import { post } from "@src/appV2/api/api";
import { API_TYPES } from "@src/appV2/api/constants";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

export const createPlacementApplicationBodySchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.placementApplication),
    relationships: z.object({
      placementCandidate: z.object({
        data: z.object({
          type: z.literal(API_TYPES.placementCandidate),
          id: z.string(),
        }),
      }),
      placement: z.object({
        data: z.object({
          type: z.literal(API_TYPES.placement),
          id: z.string(),
        }),
      }),
    }),
  }),
});
export type CreatePlacementApplicationBody = z.infer<typeof createPlacementApplicationBodySchema>;

interface CreatePlacementApplicationParams {
  placementCandidateId: string;
  placementId: string;
}

export function useCreatePlacementApplication(
  options: UseMutationOptions<void, string, CreatePlacementApplicationParams> = {}
) {
  return useMutation({
    mutationFn: async (params) => {
      const { placementCandidateId, placementId } = params;

      await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/placement-applications`,
        data: {
          data: {
            type: API_TYPES.placementApplication,
            relationships: {
              placementCandidate: {
                data: {
                  type: API_TYPES.placementCandidate,
                  id: placementCandidateId,
                },
              },
              placement: {
                data: {
                  type: API_TYPES.placement,
                  id: placementId,
                },
              },
            },
          },
        },
        responseSchema: z.unknown(),
        requestSchema: createPlacementApplicationBodySchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PLACEMENT_APPLICATION_CREATE_FAILURE,
    },
    ...options,
  });
}
