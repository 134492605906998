import { API_TYPES, get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import {
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { isEqual } from "lodash";
import { type z } from "zod";

import {
  type GetPlacementsForWorkplaceQuery,
  getPlacementsForWorkplaceQuerySchema,
  type GetPlacementsResponse,
  paginatedGetPlacementsResponseSchema,
  type placementDataSchema,
  type PlacementIncludedApplication,
  type PlacementIncludedWorkplace,
} from "../types/fetchPlacements.schema";

export function fetchPlacementsApiUrl(workerId: string) {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/placements`;
}

interface FetchPlacementsParams {
  workerId: string;
  placementCandidateId: string;
  filter?: GetPlacementsForWorkplaceQuery["filter"];
  defaultFilter?: GetPlacementsForWorkplaceQuery["filter"];
}

export function useFetchPaginatedPlacements(
  params: FetchPlacementsParams,
  options: UseInfiniteQueryOptions<GetPlacementsResponse, AxiosError> = {}
) {
  const { workerId, placementCandidateId, filter, defaultFilter } = params;

  return useInfiniteQuery({
    queryKey: [fetchPlacementsApiUrl(workerId), filter],
    queryFn: async ({
      pageParam: pageParameter = undefined,
    }: QueryFunctionContext<QueryKey, string | undefined>) => {
      const nonEmptyFilter = Object.fromEntries(
        Object.entries(filter ?? {}).filter(([_, value]) => value)
      );
      const response = await get({
        url: fetchPlacementsApiUrl(workerId),
        queryParams: {
          page: {
            size: 50,
            cursor: pageParameter,
          },
          filter: nonEmptyFilter,
        },
        responseSchema: paginatedGetPlacementsResponseSchema,
        requestSchema: getPlacementsForWorkplaceQuerySchema,
      });
      logEvent(APP_V2_APP_EVENTS.PLACEMENTS_PAGE_VIEWED, {
        placementCandidateId,
        filter: nonEmptyFilter,
        defaultFilter,
        wasFilterChanged: !isEqual(nonEmptyFilter, defaultFilter),
      });
      return response.data;
    },
    getNextPageParam: (lastPage) => lastPage.links.nextCursor ?? undefined,
    ...options,
  });
}

export function transformResponseIntoPlacement(
  placement: z.infer<typeof placementDataSchema>,
  placementIncludedMetaData: GetPlacementsResponse["included"]
) {
  const workplace = placementIncludedMetaData.find(
    (included) =>
      included.type === API_TYPES.workplace &&
      included.id === placement.relationships.workplace.data.id
  ) as PlacementIncludedWorkplace | undefined;

  if (!workplace) {
    throw new Error("Workplace details not found");
  }

  const applications = placement.relationships.applications?.data.map((application) => {
    const applicationData = placementIncludedMetaData.find(
      (included) =>
        included.type === API_TYPES.placementApplication && included.id === application.id
    ) as PlacementIncludedApplication | undefined;
    if (!applicationData) {
      throw new Error("Application details not found");
    }

    return applicationData;
  });

  return {
    ...placement.attributes,
    id: placement.id,
    workplace: { ...workplace.attributes, id: workplace.id },
    applications,
  };
}

export type PlacementWithDetails = ReturnType<typeof transformResponseIntoPlacement>;
