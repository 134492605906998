export const PLACEMENTS_BASE_PATH = "placements";

export const PLACEMENT_PATHS = {
  ROOT: PLACEMENTS_BASE_PATH,
  DETAILS: `/home-v2/${PLACEMENTS_BASE_PATH}/:placementId/placement-candidate/:placementCandidateId?`,
} as const;

export function getPlacementDetailsPath(placementId: string, placementCandidateId: string): string {
  return PLACEMENT_PATHS.DETAILS.replace(":placementId", placementId).replace(
    ":placementCandidateId",
    placementCandidateId
  );
}
