import { BaseLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Card, CardActionArea, CardContent, Chip, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { formatDistanceInMiles } from "@src/appV2/lib/utils/distance";
import { jobTypeOptions, shiftTypeOptions } from "@src/appV2/PlacementCandidate/constants";
import { CbhIcon } from "@src/appV2/ShiftDiscovery/components/CbhIcon";
import { DotSeparatedList } from "@src/appV2/ShiftDiscovery/components/DotSeparatedList";
import { formatDistance, parseISO } from "date-fns";

import { type PlacementDetail } from "../api/useGetPlacement";
import { getPlacementDetailsPath } from "../paths";
import { PlacementPerk } from "../types/fetchPlacements.schema";

interface PlacementCardItemProps {
  placement: PlacementDetail;
  placementCandidateId: string;
  pageIndex: number;
  placementIndex: number;
}

export function PlacementCardItem(props: PlacementCardItemProps) {
  const { placement, placementCandidateId, pageIndex, placementIndex } = props;

  const hasApplied = isDefined(placement.applications) && placement.applications.length > 0;
  const appliedDate = placement.applications?.[0]?.attributes.appliedDate;

  const hasPayRateRange =
    isDefined(placement.payRange) &&
    isDefined(placement.payRange.minPay?.amountInMinorUnits) &&
    isDefined(placement.payRange.maxPay?.amountInMinorUnits);
  const visiblePayRate =
    placement.payRange?.minPay?.amountInMinorUnits ??
    placement.payRange?.maxPay?.amountInMinorUnits;

  const shiftTypeAndSpecialPerkElements = [
    ...placement.shiftTypes.map((shiftType) => {
      const shiftTypeOption = shiftTypeOptions.find((option) => option.value === shiftType);
      if (!isDefined(shiftTypeOption)) {
        return null;
      }

      return (
        <Stack key={shiftType} direction="row" spacing={1} alignItems="center">
          <CbhIcon type={shiftTypeOption.icon} size="small" />
          <Text variant="body2" color="text.secondary">
            {shiftTypeOption.label}
          </Text>
        </Stack>
      );
    }),
    placement.perks?.includes(PlacementPerk.TWELVE_SHIFTS_AVAILABLE) ? (
      <Stack
        key="twelve-hour"
        direction="row"
        spacing={1}
        alignItems="center"
        flexShrink={0}
        flexWrap="nowrap"
      >
        <Text variant="body2" color="text.secondary">
          12h shifts
        </Text>
      </Stack>
    ) : null,
  ].filter(Boolean);
  return (
    <Card
      variant="elevation"
      sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
    >
      <CardActionArea
        component={BaseLink}
        to={getPlacementDetailsPath(placement.id, placementCandidateId)}
        onClick={() => {
          logEvent(APP_V2_APP_EVENTS.PLACEMENT_DETAILS_PAGE_VIEWED, {
            placementCandidateId,
            placementDetails: placement,
            page: pageIndex + 1,
            listIndex: placementIndex + 1,
            hasApplied,
            appliedDate,
          });
        }}
      >
        <CardContent>
          <Stack spacing={8}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={2}
              alignItems="flex-start"
            >
              <Stack spacing={2} flex={1}>
                {hasApplied && isDefined(appliedDate) && (
                  <Box sx={{ paddingBottom: 6 }}>
                    <Chip
                      label={`Applied ${formatDistance(new Date(), parseISO(appliedDate))} ago`}
                      variant="filled"
                      size="small"
                    />
                  </Box>
                )}

                <Text variant="body1" sx={{ fontWeight: 600 }}>
                  {placement.jobTypes
                    .map(
                      (jobType) => jobTypeOptions.find((option) => option.value === jobType)?.label
                    )
                    .join(", ")}
                </Text>

                <DotSeparatedList>{shiftTypeAndSpecialPerkElements}</DotSeparatedList>
              </Stack>
              <Stack alignItems="center" spacing={1}>
                {isDefined(placement.workerTypes) && (
                  <Chip
                    label={placement.workerTypes.join(", ")}
                    variant="outlined"
                    sx={{
                      border: "0.5px solid",
                      borderColor: (theme) => theme.palette.border?.primary,
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={4}>
              <Stack>
                <Text
                  semibold
                  variant="body1"
                  sx={{
                    fontSize: placement.workplace.name.length > 20 ? 14 : "inherit",
                  }}
                >
                  {placement.workplace.name}
                </Text>
                <Text variant="body2" color="text.secondary">
                  {formatDistanceInMiles(placement.distance)} from home
                </Text>
              </Stack>
              {visiblePayRate && (
                <Stack
                  alignItems="flex-end"
                  flexWrap="nowrap"
                  flexShrink={0}
                  sx={{
                    padding: 4,
                    border: "0.5px solid",
                    borderColor: (theme) => theme.palette.border?.primary,
                    borderRadius: 4,
                  }}
                >
                  {hasPayRateRange && (
                    <Text variant="caption" color="text.secondary">
                      Starts from
                    </Text>
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Text variant="h5" textAlign="center" sx={{ fontWeight: 600 }}>
                      {formatCentsAsUsd(visiblePayRate)}
                    </Text>
                    <Text variant="body2" color="text.secondary">
                      /hr
                    </Text>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
