import { Skeleton, Stack } from "@mui/material";

export function PlacementDetailsSkeleton() {
  return (
    <Stack spacing={3} flex={1} height="100%">
      <Skeleton variant="rounded" height={60} />
      <Stack spacing={2}>
        <Skeleton variant="text" width="60%" height={30} />
        <Skeleton variant="text" width="40%" height={25} />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Skeleton variant="rounded" width={80} height={32} />
        <Skeleton variant="rounded" width={80} height={32} />
        <Skeleton variant="rounded" width={80} height={32} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton variant="text" width="70%" height={25} />
        <Skeleton variant="text" width="40%" height={20} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="60%" />
      </Stack>
    </Stack>
  );
}
