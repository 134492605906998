import { formatCentsAsUsd } from "../lib/Money/utils/currency";
import { type PlacementDetail } from "./api/useGetPlacement";

export function formatPayRange(payRange: PlacementDetail["payRange"]) {
  const minPay = payRange?.minPay?.amountInMinorUnits;
  const maxPay = payRange?.maxPay?.amountInMinorUnits;

  if (!minPay && !maxPay) {
    return undefined;
  }

  if (minPay && maxPay) {
    return `${formatCentsAsUsd(minPay)} /hr - ${formatCentsAsUsd(maxPay)} /hr`;
  }

  return `${formatCentsAsUsd(minPay ?? maxPay ?? 0)} /hr`;
}
