import { API_TYPES } from "@src/appV2/api";
import { JobType, ShiftNameType } from "@src/appV2/PlacementCandidate/types";
import { type IconType } from "@src/appV2/ShiftDiscovery/components/CbhIcon";
import { z } from "zod";

export const PLACEMENT_STATUS = ["draft", "open", "closed"] as const;
export type PlacementStatus = (typeof PLACEMENT_STATUS)[number];

export const PLACEMENT_APPLICATION_STATUS = ["applied", "rejected", "accepted"] as const;
export type PlacementApplicationStatus = (typeof PLACEMENT_APPLICATION_STATUS)[number];

export enum PlacementPerk {
  SHIFT_DIFFERENTIAL = "shiftDifferential",
  DAILY_PAY = "dailyPay",
  TWELVE_SHIFTS_AVAILABLE = "twelveShiftsAvailable",
  DOUBLES_AVAILABLE = "doublesAvailable",
  SIGN_ON_BONUS = "signOnBonus",
  OVERTIME_AVAILABLE = "overtimeAvailable",
}

export const PLACEMENT_PERKS: Array<{
  value: PlacementPerk;
  label: string;
  icon: IconType;
}> = [
  {
    value: PlacementPerk.SHIFT_DIFFERENTIAL,
    label: "Shift Differential",
    icon: "bolt-filled",
  },
  {
    value: PlacementPerk.DAILY_PAY,
    label: "Daily Pay",
    icon: "invite",
  },
  {
    value: PlacementPerk.TWELVE_SHIFTS_AVAILABLE,
    label: "12-Hour Shifts Available",
    icon: "clock",
  },
  {
    value: PlacementPerk.DOUBLES_AVAILABLE,
    label: "Double Shifts Available",
    icon: "bolt-filled",
  },
  {
    value: PlacementPerk.SIGN_ON_BONUS,
    label: "Sign On Bonus",
    icon: "bolt-filled",
  },
  {
    value: PlacementPerk.OVERTIME_AVAILABLE,
    label: "Overtime Available",
    icon: "bolt-filled",
  },
];

export const getPlacementsForWorkplaceQuerySchema = z.object({
  filter: z
    .object({
      distanceInMiles: z.number().optional(),
      workerTypes: z.string().optional(),
      jobTypes: z.string().optional(),
      shiftTypes: z.string().optional(),
      workplaceName: z.string().optional(),
    })
    .optional(),
  page: z
    .object({
      size: z.number().optional(),
      cursor: z.string().optional(),
    })
    .optional(),
});
export type GetPlacementsForWorkplaceQuery = z.infer<typeof getPlacementsForWorkplaceQuerySchema>;

export const placementDataSchema = z.object({
  type: z.literal(API_TYPES.placement),
  id: z.string(),
  attributes: z.object({
    status: z.enum(PLACEMENT_STATUS),
    title: z.string(),
    description: z.string().optional(),
    jobTypes: z.array(z.nativeEnum(JobType)),
    shiftTypes: z.array(z.nativeEnum(ShiftNameType)),
    workerTypes: z.array(z.string()).optional(),
    perks: z.array(z.nativeEnum(PlacementPerk)).optional(),
    validUntil: z.coerce.date().optional(),
    hiringPOC: z.object({
      name: z.string(),
      email: z.string(),
    }),
    originalJobLink: z.string().optional(),
    payRange: z
      .object({
        minPay: z
          .object({
            amountInMinorUnits: z.number(),
            currencyCode: z.string(),
          })
          .optional(),
        maxPay: z
          .object({
            amountInMinorUnits: z.number(),
            currencyCode: z.string(),
          })
          .optional(),
      })
      .optional(),
    signOnBonus: z
      .object({
        amountInMinorUnits: z.number(),
        currencyCode: z.string(),
      })
      .optional(),
    distance: z.number(),
    address: z.object({
      formatted: z.string().optional(),
    }),
    coordinates: z.array(z.number()),
  }),
  relationships: z.object({
    workplace: z.object({
      data: z.object({
        type: z.literal(API_TYPES.workplace),
        id: z.string(),
      }),
    }),
    applications: z
      .object({
        data: z.array(
          z.object({
            type: z.literal(API_TYPES.placementApplication),
            id: z.string(),
          })
        ),
      })
      .optional(),
  }),
});

const placementWorkplaceIncludeSchema = z.object({
  type: z.literal(API_TYPES.workplace),
  id: z.string(),
  attributes: z.object({
    name: z.string(),
  }),
});
export type PlacementIncludedWorkplace = z.infer<typeof placementWorkplaceIncludeSchema>;
const placementApplicationIncludeSchema = z.object({
  type: z.literal(API_TYPES.placementApplication),
  id: z.string(),
  attributes: z.object({
    appliedDate: z.string(),
  }),
});
export type PlacementIncludedApplication = z.infer<typeof placementApplicationIncludeSchema>;

export const placementIncludeSchema = z.array(
  z.union([placementWorkplaceIncludeSchema, placementApplicationIncludeSchema])
);

export const paginatedGetPlacementsResponseSchema = z.object({
  data: z.array(placementDataSchema),
  included: placementIncludeSchema,
  links: z.object({
    nextCursor: z.string().optional(),
  }),
});

export type GetPlacementsResponse = z.infer<typeof paginatedGetPlacementsResponseSchema>;

export const placementResponseSchema = z.object({
  data: placementDataSchema,
  included: placementIncludeSchema,
});
export type PlacementResponse = z.infer<typeof placementResponseSchema>;
