import { LoadingButton } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import { APP_V2_APP_EVENTS, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { type QueryObserverResult } from "@tanstack/react-query";

import { useCreatePlacementApplication } from "../api/useCreatePlacementApplication";
import { type PlacementDetail } from "../api/useGetPlacement";
import { type PlacementResponse } from "../types/fetchPlacements.schema";

interface PlacementApplyButtonContainerProps {
  placementDetails: PlacementDetail;
  placementCandidateId: string;
  refetchPlacement: () => Promise<QueryObserverResult<PlacementResponse>>;
}

export function PlacementApplyButtonContainer(props: PlacementApplyButtonContainerProps) {
  const { placementDetails, placementCandidateId, refetchPlacement } = props;
  const { showErrorToast, showSuccessToast } = useToast();
  const { mutateAsync: createPlacementApplication, isLoading: isCreatingPlacementApplication } =
    useCreatePlacementApplication();

  const hasApplied =
    isDefined(placementDetails) && (placementDetails.applications?.length ?? 0) > 0;
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        paddingX: 4,
        paddingY: 6,
        backgroundColor: (theme) => theme.palette.background.tertiary,
        borderTop: 1,
        borderColor: "divider",
        zIndex: 1200,
      }}
    >
      {isDefined(placementDetails) && isDefined(placementCandidateId) && (
        <LoadingButton
          fullWidth
          variant="contained"
          size="large"
          color="primary"
          isLoading={isCreatingPlacementApplication}
          disabled={hasApplied}
          onClick={async () => {
            try {
              await createPlacementApplication({
                placementCandidateId,
                placementId: placementDetails.id,
              });
              await refetchPlacement();
              showSuccessToast(
                `You've successfully applied to ${placementDetails.workplace.name}! Please check your email for next steps.`
              );
              logEvent(APP_V2_APP_EVENTS.PLACEMENT_APPLICATION_CREATED, {
                placementCandidateId,
                placementDetails,
              });
            } catch {
              showErrorToast("Failed to apply for job.");
            }
          }}
        >
          {hasApplied ? "Applied" : "Apply Now"}
        </LoadingButton>
      )}
    </Box>
  );
}
